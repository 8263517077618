import React from 'react';
import './index.scss';

export default function NotFound() {
  return (
    <div className="content-loader-total">
      <img src="/img/loader-big.svg" alt="loader" />
    </div>
  );
}
